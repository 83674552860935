import React from "react";

function MenuTitle(props) {
  return (
    <div className="d-flex align-items-center">
      <h3 className="menu-title gold-text mb-3 mt-5 flex-grow-1">
        {props.title}
      </h3>
    </div>
  );
}

export default MenuTitle;
