export default {
  // Navigation.
  Navigation: {
    MENU: "RESTAURANT MENU",
    TAKEAWAY_MENU: "TAKEAWAY MENU",
    CONTACT: "CONTACT",
  },

  Footer: {
    DESCRIPTION:
      "IN CASE OF ALLERGY TO ANY OF OUR PRODUCTS, CUSTOMER MUST INFORM THE WAITER OR THE STORE MANAGER IN TIME.",
    MANAGER: "MARKET REGULATOR: VARDALOS ANTONIOS",
  },

  Contact: {
    ADDRESS: "Address",
    ADDRESS_TEXT: "Leof. El. Venizelou 190, Ilioupoli",
    TELEPHONE: "Phone Number",
    EMAIL: "Email",
  },

  MELANZANAFRITTA: "SAN MARZANO / PARMEZANA REGGIANO / TOMATOE COMPOTE",
  VITELLOTONNATO: "MILK BEEF / TUNA SAUCE / FRIED CAPERS / PARMESAN COOKIE",
  SICILIANPOLPETTE:
    "BEEF MEATBALLS WITH SAN MARZANO TOMATO SAUCE AND PARMESAN CREAM",
  CROCCEDIGALLO: "WOOD OVEN COOKED ROOSTER / SEASONAL / VEGETABLES / CORN",

  // Pizza.
  PRINCIPESSAMARGHERITA:
    "SAN MARZANO TOMATO / MOZZARELLA FIOR DI LATTE / PARMESAN REGGIANO / FRESH BASIL",
  PRINCIPESSAMARGHERITA_ADDITIONAL: "+ BURRATA 125GR",
  ANABELLA:
    "SAN MARZANO TOMATO / NDUJA / VENTRICINA / MOZZARELLA FIOR DI LATTE / PARMESAN REGGIANO / CHILLI HONEY / FRESH BASIL",
  GUSTINA:
    "TRUFFLE CREAM / MOZZARELLA FIOR DI LATTE / PORTOBELLO MUSHROOM / BABY ROCKET / PARMESAN REGGIANO",
  GIOCONDA:
    "MOZZARELLA FIOR DI LATTE / GORGONZOLA DOLCE / PROVOLA / PARMESAN REGGIANO",
  ISABELLA:
    "SAN MARZANO TOMATO / SMOKED EGGPLANT CREAM / CARAMELIZED ONION / PROVOLA AFFUMICATA",
  BEATRICE:
    "SLOW COOKED BEEF RAGU / MOZZARELLA FIOR DI LATTE / PARMESAN REGGIANO / THYME, BASIL / RICOTTA CHEESE",
  FRANCESCA:
    "SAN MARZANO TOMATO / MOZZARELLA FIOR DI LATTE / PROSCIUTTO DI PARMA 24 MESI / BABY ROCKET / PARMESAN REGGIANO FLAKES / SEMI-DRY POMODORINI",
  GLORIA:
    "YELLOW PEPPER CREAM / GOAT CHEESE / MOZZARELLA FIOR DI LATTE / PANCETTA AFFUMICATA",
  LOLITA:
    "MORTADELLA HAM / PISTACHIO / MOZZARELLA FIOR DI LATTE / RICOTTA CHEESE / BURRATA MOZZARELLA / BASIL PESTO",
  ROSINA:
    "SAN MARZANO TOMATO / FRESH OREGANO / BASIL / GARLIC / EXTRA VIRGIN OLIVE OIL",
  ROSINA_ADDITIONAL: "+ STRACCIATELLA DI BUFFALA",

  // Pasta Fresca.
  ALESSANDRAALLACHITARRA:
    "SAN MARZANO TOMATO SAUCE / FRESH BASIL / BURATTA CHEESE",
  CARLOTTATORTIGLIONI: "FRESHLY GROUND PEPPER / TRUFFLE PECORINO CHEESE",
  CARLOTTATORTIGLIONI_ADDITIONAL: "+ PISTACHIO",
  GIOLINAALLACHITARRA:
    "EGGS / QUANCIALE / PECORINO ROMANO / FRESHLY GROUND PEPPER",
  GALILEATORTELLI:
    "ROOSTER / SMOKED LEEK AND POTATO CREAM IN THE WOOD OVEN / PINE",
  LUDOVICAMALFADINE:
    "TRUFFLE CREAM / PORTOBELLO MUSHROOM / CHIVES / PARMESAN REGIANO",
  ANTONELLAALLACHITARRA: "SHRIMPS / ZUCHINI / LIME / CHILLI / SHRIMP BROTH",
  VALENTINAALLACHITARRA:
    "PESTO BASIL / SEMI-DRY POMODORINI / BURRATA MOZZARELLA / ROASTED PINE NUTS",
  CHIARAPAPPARDELLE:
    "SLOW COOKED BEEF RAGU / PECORINO DOP / FRESH THYME / SEMI-DRY POMODORINI",
  GABRIELLAGNOCCHIRICOTTA: "GORGONZOLA CREAM / PROSCIUTTO DI SAN DANIELE",

  // Insalata.
  BURRATAPANZANELLA:
    "MOZZARELLA BURRATA / CHERRY TOMATOES / PICKLED ONIONS / PEPPER CREAM",
  JULIUSCEASAR:
    "CRISPY CHICKEN CUTLET / PARMESAN FLAKES / HOMEMADE CAESAR DRESSING / LETTUCE AND GUANCIALE POWDER / FOCACCIA",
  RUCOLA:
    "SEASONAL FRUIT / GORGONZOLA DOLCE / CARAMELIZED HAZELNUTS / VINEGRAIT CITRUS",
  SPINACIDIFAGIOLI:
    "SPINACH / GREEN BEAN / GREEN APPLE / RICOTTA / VINEGRAITTE VINSANTO / ALMOND FRIEND",

  // Dolci.
  TIRAMISU: "TIRAMISU",
  LUNA: "LUNA",
  TORTALIMONE: "TORTA LIMONE",
  RISOTTODOLCE: "RISOTTO DOLCE",
  ESPRESSO: "ESPRESSO",
  AFFOGATOESPRESSO: "AFFOGATO ESPRESSO",

  // Cocktails.
  PRINCESSPEACH: "APEROL / GIN / PROSECCO",
  YOSHI: "GRAPPA / ESPRESSO / BITTER ANGOSTURA",
  COOPATROOPA: "LIMONCELLO / PROSECCO / SODA",
  WARRIO: "AGED NEGRONI 2 WEEKS",

  // Beverages.
  ARANCIATA: "ARANCIATA / ORANGE",
  LIMONATA: "LIMONATA / LEMON",
  MANDARINO: "MANDARINO / MANDARINE",
  GASSOSA: "GASSOSA",
  AQUA_1000: "AQUA SAN BENEDETTO MINERAL 1000ML",
  AQUA_250: "AQUA SAN BENEDETTO SPARKLING 250ML",
  AQUA_750: "AQUA SAN BENEDETTO SPARKLING 750ML",
  COCACOLA: "COCA COLA / ZERO",
};
