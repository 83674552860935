export const Translations = {
  // Navigation.
  NAVIGATION: {
    MENU: "Navigation.MENU",
    TAKEAWAY_MENU: "Navigation.TAKEAWAY_MENU",
    CONTACT: "Navigation.CONTACT",
  },

  FOOTER: {
    DESCRIPTION: "Footer.DESCRIPTION",
    MANAGER: "Footer.MANAGER",
  },

  CONTACT: {
    ADDRESS: "Contact.ADDRESS",
    ADDRESS_TEXT: "Contact.ADDRESS_TEXT",
    TELEPHONE: "Contact.TELEPHONE",
    EMAIL: "Contact.EMAIL",
  },

  // Menu.
  MELANZANAFRITTA: "MELANZANAFRITTA",
  VITELLOTONNATO: "VITELLOTONNATO",
  SICILIANPOLPETTE: "SICILIANPOLPETTE",
  CROCCEDIGALLO: "CROCCEDIGALLO",

  // Pizza.
  PRINCIPESSAMARGHERITA: "PRINCIPESSAMARGHERITA",
  PRINCIPESSAMARGHERITA_ADDITIONAL: "PRINCIPESSAMARGHERITA_ADDITIONAL",
  ANABELLA: "ANABELLA",
  GUSTINA: "GUSTINA",
  GIOCONDA: "GIOCONDA",
  ISABELLA: "ISABELLA",
  BEATRICE: "BEATRICE",
  FRANCESCA: "FRANCESCA",
  GLORIA: "GLORIA",
  LOLITA: "LOLITA",
  ROSINA: "ROSINA",
  ROSINA_ADDITIONAL: "ROSINA_ADDITIONAL",

  // Pasta Fresca.
  ALESSANDRAALLACHITARRA: "ALESSANDRAALLACHITARRA",
  CARLOTTATORTIGLIONI: "CARLOTTATORTIGLIONI",
  CARLOTTATORTIGLIONI_ADDITIONAL: "CARLOTTATORTIGLIONI_ADDITIONAL",
  GIOLINAALLACHITARRA: "GIOLINAALLACHITARRA",
  GALILEATORTELLI: "GALILEATORTELLI",
  LUDOVICAMALFADINE: "LUDOVICAMALFADINE",
  ANTONELLAALLACHITARRA: "ANTONELLAALLACHITARRA",
  VALENTINAALLACHITARRA: "VALENTINAALLACHITARRA",
  CHIARAPAPPARDELLE: "CHIARAPAPPARDELLE",
  GABRIELLAGNOCCHIRICOTTA: "GABRIELLAGNOCCHIRICOTTA",

  // Insalata.
  BURRATAPANZANELLA: "BURRATAPANZANELLA",
  JULIUSCEASAR: "JULIUSCEASAR",
  RUCOLA: "RUCOLA",
  SPINACIDIFAGIOLI: "SPINACIDIFAGIOLI",

  // Dolci.
  TIRAMISU: "TIRAMISU",
  LUNA: "LUNA",
  TORTALIMONE: "TORTALIMONE",
  RISOTTODOLCE: "RISOTTODOLCE",
  ESPRESSO: "ESPRESSO",
  AFFOGATOESPRESSO: "AFFOGATOESPRESSO",

  // Cocktails.
  PRINCESSPEACH: "PRINCESSPEACH",
  YOSHI: "YOSHI",
  COOPATROOPA: "COOPATROOPA",
  WARRIO: "WARRIO",

  // Beverages.
  ARANCIATA: "ARANCIATA",
  LIMONATA: "LIMONATA",
  MANDARINO: "MANDARINO",
  GASSOSA: "GASSOSA",
  AQUA_1000: "AQUA_1000",
  AQUA_250: "AQUA_250",
  AQUA_750: "AQUA_750",
  COCACOLA: "COCACOLA",
};
