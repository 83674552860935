import React from "react";
import { useTranslation } from "react-i18next";
import { Translations } from "../i18n/translationKeys";

function Contact() {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className="center-container gold-text d-flex flex-column contact-container"
        style={{ alignItems: "center" }}
      >
        <div className="mb-5">
          <h1 className="h3 font-light mt-5 contact-text">
            <b>{t(Translations.CONTACT.ADDRESS)}:</b>{" "}
            {t(Translations.CONTACT.ADDRESS_TEXT)}
          </h1>
          <h1 className="h3 font-light mt-3 contact-text">
            <b>{t(Translations.CONTACT.TELEPHONE)}:</b> 21 0995 7953
          </h1>

          <h1 className="h3 font-light mt-3 contact-text">
            <b>Email:</b>{" "}
            <a
              className="gold-text"
              href="mailto:marioluigieatalians@gmail.com"
            >
              marioluigieatalians@gmail.com
            </a>
          </h1>

          <h1 className="h3 font-light mt-3 contact-text">
            <b>Follow us:</b>{" "}
            <a
              href="https://www.facebook.com/marioandluigieatalians"
              className="gold-text"
              target="_blank"
            >
              Facebook
            </a>{" "}
            &{" "}
            <a
              href="https://www.instagram.com/mario_luigi_eatalians/"
              className="gold-text"
              target="_blank"
            >
              Instagram
            </a>
          </h1>

          <iframe
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=The+Eatalians+-+Mario+%26+Luigi&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            frameborder="0"
            scrolling="no"
            class="embed-content"
            title="Map view"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
