// @ts-nocheck
import React from "react";
import { useTranslation } from "react-i18next";

export const MenuItemV2 = (props) => {
  const { title, price, description, subtitle, additionalItems } = props;
  const { i18n } = useTranslation();
  const isGreek = i18n.language === "el";

  const subtitleToShow = subtitle
    ? !!title
      ? ` / ${subtitle}`
      : subtitle
    : "";

  function formatPrice(price) {
    const [int, dec] = price.split(".");
    if (dec !== "00") {
      price = `${int},${dec.slice(0, 1)}`;
    } else {
      price = `${int}`;
    }
    return price;
  }

  return (
    <div className="d-flex flex-column mb-3">
      <div className="d-flex flex-grow-1">
        <div className="flex-grow-1 pe-4">
          <h3 className="h5 gold-text mb-0 text-uppercase font-medium menu-item-title">
            {title}
            {subtitle && (
              <span className="ml-3 font-thin ml-2">{subtitleToShow}</span>
            )}
          </h3>
          <p className="menu-desc gold-text font-light mb-0">{description}</p>
        </div>

        <h3 className="h4 font-thin gold-text ml-5 mr-3">
          {formatPrice(price)}
        </h3>
      </div>

      {additionalItems.map((i) => (
        <div key={i.id} className="d-flex flex-grow-1 align-items-center">
          <div className="flex-grow-1 pe-4">
            <p className="menu-desc gold-text font-light mb-0">
              + {isGreek ? i.name_gr : i.name_en}
            </p>
          </div>

          <h3 className="h4 font-thin gold-text ml-5 mr-3 mb-0">
            +{formatPrice(i.price)}
          </h3>
        </div>
      ))}
    </div>
  );
};
