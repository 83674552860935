// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import el from "./locales/el";

const resources = {
  el: {
    translation: { ...el },
  },
  en: {
    translation: { ...en },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "el",
  fallbackLng: "el",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
