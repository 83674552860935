import React from "react";

function Home(props) {
  return (
    <div>
      <div
        className="center-container gold-text d-flex flex-column"
        style={{ alignItems: "center" }}
      >
        <div className="mb-5">
          <h1 className="h2 font-light mt-5">
            Ανακάλυψε <br></br>την Ιταλική
          </h1>
          <h1 className="title-font">Finezza!</h1>
        </div>

        <div>
          <h1 className="h2 gold-text text-center footer-text mt-5 font-light">
            PIZZA & PASTA
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Home;
