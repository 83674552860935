import React from "react";

export const Admin = () => {
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
        border: "none",
        position: "fixed",
        overflow: "hidden",
        zIndex: "9999",
      }}
      title="Admin"
      src="https://eatalians-admin.vercel.app/"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
};
