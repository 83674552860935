export default {
  // Navigation.
  Navigation: {
    MENU: "ΚΑΤΑΛΟΓΟΣ ΕΣΤΙΑΤΟΡΙΟΥ",
    TAKEAWAY_MENU: "ΚΑΤΑΛΟΓΟΣ TAKEAWAY",
    CONTACT: "ΕΠΙΚΟΙΝΩΝΙΑ",
  },

  Footer: {
    DESCRIPTION:
      "ΣΕ ΠΕΡΙΠΤΩΣΗ ΑΛΛΕΡΓΙΑΣ ΣΕ ΚΑΠΟΙΟ ΑΠΟ ΤΑ ΠΡΟΙΟΝΤΑ ΜΑΣ, Ο ΠΕΛΑΤΗΣ ΟΦΕΙΛΕΙ ΝΑ ΕΝΗΜΕΡΩΣΕΙ ΤΟΝ ΣΕΡΒΙΤΟΡΟ Ή ΤΟΝ ΥΠΕΥΘΥΝΟ ΤΟΥ ΚΑΤΑΣΤΗΜΑΤΟΣ ΕΓΚΑΙΡΩΣ.",
    MANAGER: "ΑΓΟΡΑΝΟΜΙΚΟΣ ΥΠΕΥΘΥΝΟΣ : ΒΑΡΔΑΛΟΣ ΑΝΤΩΝΙΟΣ",
  },

  Contact: {
    ADDRESS: "Διεύθυνση",
    ADDRESS_TEXT: "Λεωφ. Ελευθέριου Βενιζέλου 190, Ηλιούπολη",
    TELEPHONE: "Τηλέφωνο",
    EMAIL: "Email",
  },

  MELANZANAFRITTA: "SAN MARZANO / ΠΑΡΜΕΖΑΝΑ REGGIANO / COMPOTE ΝΤΟΜΑΤΑΣ",
  VITELLOTONNATO:
    "ΜΟΣΧΑΡΙ ΓΑΛΑΚΤΟΣ / ΣΑΛΤΣΑ ΤΟΝΟΥ / ΤΗΓΑΝΙΤΗ ΚΑΠΑΡΗ / ΜΠΙΣΚΟΤΟ ΠΑΡΜΕΖΑΝΑΣ",
  SICILIANPOLPETTE:
    "ΣΙΚΕΛΙΑΝΙΚΑ ΚΕΦΤΕΔΑΚΙΑ / ΣΑΛΤΣΑ ΝΤΟΜΑΤΑΣ / ΚΡΕΜΑ ΠΑΡΜΕΖΑΝΑΣ / ΑΦΡΑΤΗ FOCACCIA",
  CROCCEDIGALLO: "ΚΟΚΚΟΡΑΣ ΣΤΟΝ ΞΥΛΟΦΟΥΡΝΟ / ΛΑΧΑΝΙΚΑ ΕΠΟΧΗΣ / ΚΑΛΑΜΠΟΚΙ",

  // Pizza.
  PRINCIPESSAMARGHERITA:
    "ΣΑΛΤΣΑ SAN MARZANO / ΜΟΤΣΑΡΕΛΑ FIOR DI LATTE / ΠΑΡΜΕΖΑΝΑ REGGIANO / ΒΑΣΙΛΙΚΟΣ",
  PRINCIPESSAMARGHERITA_ADDITIONAL: "+ ΜΟΤΣΑΡΕΛΑ BURRATA 125GR",
  ANABELLA:
    "ΣΑΛΤΣΑ SAN MARZANO / ΜΟΤΣΑΡΕΛΑ FIOR DI LATTE / SALAME NDUJA / VENTRICINA / ΠΑΡΜΕΖΑΝΑ REGGIANO / CHILLI ΜΕΛΙ / ΦΡΕΣΚΟΣ ΒΑΣΙΛΙΚΟΣ",
  GUSTINA:
    "ΚΡΕΜΑ ΤΡΟΥΦΑΣ / ΜΟΤΣΑΡΕΛΑ FIOR DI LATTE / ΜΑΝΙΤΑΡΙΑ PORTOBELLO / ΡΟΚΑ / ΠΑΡΜΕΖΑΝΑ REGGIANO",
  GIOCONDA:
    "ΜΟΤΣΑΡΕΛΑ FIOR DI LATTE / ΤΥΡΙ GORGONZOLA DOLCE / ΤΥΡΙ PROVOLA / ΠΑΡΜΕΖΑΝΑ REGGIANO",
  ISABELLA:
    "ΣΑΛΤΣΑ SAN MARZANO / ΚΡΕΜΑ ΚΑΠΝΙΣΤΗΣ ΜΕΛΙΤΖΑΝΑΣ / ΚΑΡΑΜΕΛΩΜΕΝΟ ΚΡΕΜΜΥΔΙ / ΚΑΠΝΙΣΤΗ PROVOLA",
  BEATRICE:
    "RAGU ΜΟΣΧΑΡΙ / ΜΟΤΣΑΡΕΛΑ FIOR DI LATTE / ΣΑΛΤΣΑ SAN MARZANO / ΠΑΡΜΕΖΑΝΑ REGGIANO / ΤΥΡΙ RICOTTA",
  FRANCESCA:
    "ΣΑΛΤΣΑ SAN MARZANO / ΜΟΤΣΑΡΕΛΑ FIOR DI LATTE / ΠΡΟΣΟΥΤΟ DI PARMA 24 MESI / ΡΟΚΑ / ΠΑΡΜΕΖΑΝΑ REGGIANO / ΤΟΜΑΤΙΝΙΑ SEMI-DRY",
  GLORIA:
    "ΚΡΕΜΑ ΚΙΤΡΙΝΗΣ ΠΙΠΕΡΙΑΣ / ΚΑΤΣΙΚΙΣΙΟ ΤΥΡΙ / ΜΟΤΣΑΡΕΛΑ FIOR DI LATTE / PANCETTA AFFUMICATA",
  LOLITA:
    "ΜΟΡΤΑΔΕΛΑ / ΦΥΣΤΙΚΙ ΑΙΓΙΝΗΣ / ΜΟΤΣΑΡΕΛΑ FIOR DI LATTE / ΤΥΡΙ RICOTTA / ΜΟΤΣΑΡΕΛΑ BURRATA / ΠΕΣΤΟ ΒΑΣΙΛΙΚΟΥ",
  ROSINA:
    "ΣΑΛΤΣΑ SAN MARZANO / ΦΡΕΣΚΙΑ ΡΙΓΑΝΗ / ΒΑΣΙΛΙΚΟΣ / ΣΚΟΡΔΟ / ΕΛΑΙΟΛΑΔΟ",
  ROSINA_ADDITIONAL: "+ STRACCIATELLA DI BUFFALA",

  // Pasta Fresca.
  ALESSANDRAALLACHITARRA:
    "ΣΑΛΤΣΑ ΝΤΟΜΑΤΑΣ / ΦΡΕΣΚΟΣ ΒΑΣΙΛΙΚΟΣ / ΜΟΤΣΑΡΕΛΑ BURATTA",
  CARLOTTATORTIGLIONI: "ΤΥΡΙ PECORINO ΤΡΟΥΦΑΣ / ΦΡΕΣΚΟΤΡΙΜΜΕΝΟ ΠΙΠΕΡΙ",
  CARLOTTATORTIGLIONI_ADDITIONAL: "+ ΦΥΣΤΙΚΙ",
  GIOLINAALLACHITARRA:
    "ΧΟΙΡΙΝΟ ΜΑΓΟΥΛΟ GUANCIALE / ΚΡΟΚΟΣ ΑΥΓΟΥ / ΤΥΡΙ PECORINO ROMANO",
  GALILEATORTELLI:
    "ΚΟΚΚΟΡΑΣ / ΚΡΕΜΑ ΚΑΠΝΙΣΤΟΥ ΠΡΑΣΟΥ ΚΑΙ ΠΑΤΑΤΑΣ ΣΤΟΝ ΞΥΛΟΦΟΥΡΝΟ / ΚΟΥΚΟΥΝΑΡΙ",
  LUDOVICAMALFADINE: "ΠΑΣΤΑ ΤΡΟΥΦΑΣ / ΚΡΕΜΑ ΠΑΡΜΕΖΑΝΑΣ / ΜΑΝΙΤΑΡΙΑ",
  ANTONELLAALLACHITARRA: "ΓΑΡΙΔΕΣ / ΚΟΛΟΚΥΘΙ / BISQUE ΓΑΡΙΔΑΣ / LIME / CHILLI",
  VALENTINAALLACHITARRA:
    "PESTO ΒΑΣΙΛΙΚΟΥ / ΤΟΜΑΤΙΝΙΑ SEMI-DRY / ΚΟΥΚΟΥΝΑΡΙ / ΜΟΤΣΑΡΕΛΑ BURATTA",
  CHIARAPAPPARDELLE:
    "RAGU ΜΟΣΧΑΡΙ / ΤΥΡΙ PECORINO ROMANO / ΘΥΜΑΡΙ / ΤΟΜΑΤΙΝΙΑ SEMI-DRY",
  GABRIELLAGNOCCHIRICOTTA: "ΚΡΕΜΑ GORGONZOLA PICCANTE / ΠΡΟΣΟΥΤΟ COTTO",

  // Insalata.
  BURRATAPANZANELLA: "ΤΟΜΑΤΙΝΙΑ / ΠΙΠΕΡΙΑ / ΚΡΕΜΜΥΔΙ ΤΟΥΡΣΙ / ΚΑΠΑΡΗ / ΡΙΓΑΝΗ",
  JULIUSCEASAR:
    "ΤΡΑΓΑΝΟ ΚΟΤΟΠΟΥΛΟ / ΠΟΥΔΡΑ GUANCIALE / ΠΑΡΜΕΖΑΝΑ / JC SAUCE / ΤΡΑΓΑΝΗ FOCACCIA",
  RUCOLA:
    "ΦΡΟΥΤΟ ΕΠΟΧΗΣ / GORGONZOLA DOLCE / ΚΑΡΑΜΕΛΩΜΕΝΟ ΦΟΥΝΤΟΥΚΙ / VINEGRAITTE",
  SPINACIDIFAGIOLI:
    "ΣΠΑΝΑΚΙ / ΧΛΩΡΟ ΦΑΣΟΛΙ / ΠΡΑΣΙΝΟ ΜΗΛΟ / ΡΙΚΟΤΑ / VINEGRAITTE VINSANTO / ΦΙΛΕ ΑΜΥΓΔΑΛΟΥ",

  // Dolci.
  TIRAMISU: "TIRAMISU",
  LUNA: "LUNA",
  TORTALIMONE: "TORTA LIMONE",
  RISOTTODOLCE: "RISOTTO DOLCE",
  ESPRESSO: "ESPRESSO",
  AFFOGATOESPRESSO: "AFFOGATO ESPRESSO",

  // Cocktails.
  PRINCESSPEACH: "APEROL / GIN / PROSECCO",
  YOSHI: "GRAPPA / ESPRESSO / BITTER ANGOSTURA",
  COOPATROOPA: "LIMONCELLO / PROSECCO / SODA",
  WARRIO: "ΠΑΛΑΙΩΜΕΝΟ NEGRONI 2 ΕΒΔΟΜΑΔΩΝ",

  // Beverages.
  ARANCIATA: "ARANCIATA / ΠΟΡΤΟΚΑΛΙ",
  LIMONATA: "LIMONATA / ΛΕΜΟΝΙ",
  MANDARINO: "MANDARINO / ΜΑΝΤΑΡΙΝΙ",
  GASSOSA: "GASSOSA",
  AQUA_1000: "AQUA SAN BENEDETTO MINERAL 1000ML",
  AQUA_250: "AQUA SAN BENEDETTO SPARKLING 250ML",
  AQUA_750: "AQUA SAN BENEDETTO SPARKLING 750ML",
  COCACOLA: "COCA COLA / ZERO",
};
