import React from "react";
import { useTranslation } from "react-i18next";
import { Translations } from "../i18n/translationKeys";

interface OverlayProps {
  display: boolean;
  setMenu: (id: number) => void;
  setOverlay: (display: boolean) => void;
  setDidChange: (didChange: boolean) => void;
  didChange: boolean;
}

const Overlay = (props: OverlayProps) => {
  const { didChange, setMenu, setOverlay, display, setDidChange } = props;
  const { t, i18n } = useTranslation();

  const selectMenu = (id: number) => {
    if (!didChange) {
      setDidChange(!didChange);
    }
    setMenu(id);
    setOverlay(false);
  };

  return (
    <div
      className={
        didChange ? (display ? "overlay show" : "overlay hide") : "overlay"
      }
    >
      <div className="overlay-center-container">
        <h1 className="menu-item" onClick={() => selectMenu(1)}>
          {t(Translations.NAVIGATION.MENU)}
        </h1>
        <h1 className="menu-item" onClick={() => selectMenu(2)}>
          {t(Translations.NAVIGATION.TAKEAWAY_MENU)}
        </h1>
        <h1 className="menu-item" onClick={() => selectMenu(3)}>
          {t(Translations.NAVIGATION.CONTACT)}
        </h1>
        <div className="menu-item" style={{ marginTop: "10%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              alignItems: "center",
              fontSize: "18px",
              justifyContent: "center",
            }}
          >
            <span
              style={(i18n.language === "el" && { fontWeight: "bold" }) || {}}
              onClick={() => i18n.changeLanguage("el")}
            >
              EL
            </span>
            <span>/</span>
            <span
              style={(i18n.language === "en" && { fontWeight: "bold" }) || {}}
              onClick={() => i18n.changeLanguage("en")}
            >
              EN
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overlay;
