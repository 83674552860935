// @ts-nocheck
import React from "react";
import MenuTitle from "./MenuTitle";
import { MenuItemV2 } from "./MenuItemV2";
import { useTranslation } from "react-i18next";
import { Translations } from "../i18n/translationKeys";

const ROW_CUTOFF_INDEX = 3;

type ShowMenuProps = {
  menu: any;
  title: string;
  isTakeaway?: boolean;
};

export const ShowMenu = (props: ShowMenuProps) => {
  const { menu, title, isTakeaway } = props;

  const { t, i18n } = useTranslation();
  const leftMenu = menu.slice(0, ROW_CUTOFF_INDEX);
  let rightMenu = menu.slice(ROW_CUTOFF_INDEX);
  rightMenu = isTakeaway
    ? rightMenu.filter((a) => a.name_en !== "Eatalian Cocktails")
    : rightMenu;
  const isGreek = i18n.language === "el";

  return (
    <div className="center-container pb-5" style={{ textAlign: "start" }}>
      <h1 className="display-2 text-center gold-text title-font my-3-lg mt-3">
        {title}
      </h1>

      <div
        className="container-fluid row mt-4 mx-auto"
        style={{ paddingBottom: "10vh" }}
      >
        <div className="col-12 col-md-5 offset-md-1">
          {leftMenu.map((a) => (
            <React.Fragment key={a.id}>
              <MenuTitle title={isGreek ? a.name_gr : a.name_en} />
              {a.items.map((i) => (
                <div key={i.id}>
                  <MenuItemV2
                    title={isGreek ? i.name_gr : i.name_en}
                    description={isGreek ? i.ingredients_gr : i.ingredients_en}
                    additionalItems={i.additional_ingredients}
                    price={i.price}
                  />
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>

        <div className="col-12 col-md-4 offset-md-1">
          {rightMenu.map((a) => (
            <React.Fragment key={a.id}>
              <MenuTitle title={isGreek ? a.name_gr : a.name_en} />
              {a.items.map((i) => (
                <div key={i.id}>
                  <MenuItemV2
                    title={isGreek ? i.name_gr : i.name_en}
                    description={isGreek ? i.ingredients_gr : i.ingredients_en}
                    price={i.price}
                    additionalItems={i.additional_ingredients}
                  />
                </div>
              ))}
            </React.Fragment>
          ))}

          <div style={{ marginTop: "10vh" }}>
            <p className="menu-desc gold-text font-thin text-center">
              {t(Translations.FOOTER.DESCRIPTION)}
            </p>
            <p className="menu-desc gold-text font-thin text-center">
              {t(Translations.FOOTER.MANAGER)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
