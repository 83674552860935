import React, { useEffect, useState } from "react";
import "./App.css";
import Main from "./components/Main";
import Home from "./components/Home";
import Overlay from "./components/Overlay";
import Contact from "./components/Contact";
import { ShowMenu } from "./components/ShowMenu";

function App({ initialRoute }: { initialRoute: number }) {
  const [displayOverlay, setOverlay] = useState(false);
  const [menu, setMenu] = useState([]);
  const [route, setRoute] = useState(initialRoute);
  const [didChange, setDidChange] = useState(false);

  useEffect(() => {
    // Fetch Menu.
    async function fetchMenu() {
      const response = await fetch(
        "https://eatalians-admin.vercel.app/api/fetchMenu",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setMenu(data.menu);
    }

    fetchMenu();
  }, []);

  return (
    <div className="app">
      <Main
        displayOverlay={displayOverlay}
        setOverlay={setOverlay}
        menu={route}
        setMenu={setRoute}
        didChange={didChange}
        setDidChange={setDidChange}
      />

      {route === 0 ? (
        <Home />
      ) : route === 1 ? (
        <ShowMenu title="Menu" menu={menu} />
      ) : route === 2 ? (
        <ShowMenu title="Takeaway" menu={menu} isTakeaway />
      ) : route === 3 ? (
        <Contact />
      ) : null}

      <Overlay
        display={displayOverlay}
        setMenu={setRoute}
        setOverlay={setOverlay}
        setDidChange={setDidChange}
        didChange={didChange}
      />
    </div>
  );
}

export default App;
