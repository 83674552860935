import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import "./index.css";
import "./i18n/i18n";
import App from "./App";
import { Login } from "./components/Login";
import * as serviceWorker from "./serviceWorker";
import { Admin } from "./components/Admin";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App initialRoute={0} />} />
        <Route path="/menu" element={<App initialRoute={1} />} />
        <Route path="/takeaway" element={<App initialRoute={2} />} />
        <Route path="/contact" element={<App initialRoute={3} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<App initialRoute={0} />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
