import React from "react";
import logo from "../logo.svg";

function Main(props) {
  const openMenu = () => {
    props.setDidChange(true);
    props.setOverlay(!props.displayOverlay);
  };

  const onLogoClickHandler = () => {
    const currentPath = window.location.pathname;
    if (currentPath === "/") {
      window.location.reload();
      return;
    }

    window.location.href = "/";
  };

  return (
    <div
      className={
        props.menu === 0 || props.menu === 2
          ? "upperContainer"
          : "upperContainer upperSecondary"
      }
    >
      <div
        className={
          props.displayOverlay
            ? "row h-100 gold-bg custom-transition"
            : "row h-100 custom-transition"
        }
      >
        <div className="col-6">
          <div
            className={
              !props.didChange
                ? "circle-container h-100"
                : props.displayOverlay
                ? "circle-container gold-bg h-100 hide"
                : "circle-container h-100 show"
            }
          >
            <div
              className={
                !props.didChange
                  ? "circle"
                  : !props.displayOverlay
                  ? "circle show"
                  : "circle hide"
              }
              onClick={() => onLogoClickHandler()}
            >
              <img src={logo} alt="logo" className="logo img-fluid"></img>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div
            className={
              props.displayOverlay
                ? "circle-container-right gold-bg h-100 hide"
                : "circle-container-right h-100 show"
            }
          >
            <div className="circle-right">
              <div
                className={
                  props.displayOverlay
                    ? "containerMenu change h-100"
                    : "containerMenu h-100"
                }
                onClick={openMenu}
              >
                <div className="bar1"></div>
                <div className="bar3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
